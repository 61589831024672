import styled from 'styled-components';
import { BreakpointUtils } from 'shared/utils';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${BreakpointUtils.deviceWidths.desktop} {
    padding-left: ${BreakpointUtils.deviceHorizontalPaddings.desktop};
    padding-right: ${BreakpointUtils.deviceHorizontalPaddings.desktop};
  }

  @media ${BreakpointUtils.deviceWidths.tablet} {
    padding-left: ${BreakpointUtils.deviceHorizontalPaddings.tablet};
    padding-right: ${BreakpointUtils.deviceHorizontalPaddings.tablet};
  }
`;
