import React from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { MicralisLogo } from 'shared/assets';
import { NavBarMenu, Button } from 'shared/ui';
import { routes, hrefs } from 'modules/main';
import { useBoolean } from '../../hooks';
import {
  StyledHeader,
  LogoContainer,
  HeaderContainer,
  ButtonContainer,
  StyledHamburgerMenu,
  DrawerButtonContainer,
} from './styledComponents';

function NavBar() {
  const history = useHistory();

  const [isHamburgerMenuOpened, toggleIsOpened] = useBoolean();

  function navigateToHome() {
    history.push(routes.home);
  }

  return (
    <StyledHeader>
      <HeaderContainer>
        <LogoContainer onClick={navigateToHome}>
          <MicralisLogo />
        </LogoContainer>
        <NavBarMenu />
        <ButtonContainer>
          <a href={hrefs.contactUs}>
            <Button title="Get in touch with us" inverted />
          </a>
        </ButtonContainer>
        <StyledHamburgerMenu
          isOpen={isHamburgerMenuOpened}
          menuClicked={toggleIsOpened}
          width={30}
          height={15}
        />
        <Drawer
          anchor="bottom"
          open={isHamburgerMenuOpened}
          onClose={toggleIsOpened}
        >
          <DrawerButtonContainer>
            <a href={hrefs.aboutUs}>
              <Button title="About us" onClick={toggleIsOpened} />
            </a>
          </DrawerButtonContainer>
          <DrawerButtonContainer>
            <a href={hrefs.howWeWork}>
              <Button title="How we work" onClick={toggleIsOpened} />
            </a>
          </DrawerButtonContainer>
          <DrawerButtonContainer>
            <Button
              title="Careers"
              onClick={() => {
                toggleIsOpened();
                history.push(routes.careers);
              }}
            />
          </DrawerButtonContainer>
          <DrawerButtonContainer>
            <a href={hrefs.contactUs}>
              <Button
                title="Get in touch with us"
                onClick={toggleIsOpened}
                inverted
              />
            </a>
          </DrawerButtonContainer>
        </Drawer>
      </HeaderContainer>
    </StyledHeader>
  );
}

export default NavBar;
