import React from 'react';
import { Paragraph } from 'shared/theme';
import { useIsPageScrollable } from 'shared/hooks';
import {
  Container,
  MottoContainer,
  Item,
  ItemLogo,
  StyledLinkedinIcon,
  SocialMediaContainer,
  SmallMicralisLogo,
  StyledPhoneIcon,
  StyledEmailIcon,
} from './styledComponents';

function PageFooter() {
  const isPageScrollable = useIsPageScrollable();

  return (
    <Container>
      <ItemLogo>{isPageScrollable && <SmallMicralisLogo />}</ItemLogo>
      <Item>
        <MottoContainer>
          <Paragraph>Your partner, not just your software agency.</Paragraph>
        </MottoContainer>
      </Item>
      <Item>
        <SocialMediaContainer>
          <div>
            <a href="https://www.linkedin.com/company/micralis">
              <StyledLinkedinIcon />
            </a>
          </div>
          <div>
            <a href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS_CONTACT}`}>
              <StyledEmailIcon />
            </a>
          </div>
          <div>
            <a href="tel:+385981985838">
              <StyledPhoneIcon />
            </a>
          </div>
        </SocialMediaContainer>
      </Item>
    </Container>
  );
}

export default PageFooter;
