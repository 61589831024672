import React from 'react';
import PropTypes from 'prop-types';
import { TextField as RFFTextField, showErrorOnBlur } from 'mui-rff';

function TextField({ label, name, multiline, required }) {
  return (
    <RFFTextField
      name={name}
      label={label}
      required={required}
      multiline={multiline}
      id="outlined-basic"
      variant="outlined"
      showError={showErrorOnBlur}
    />
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
};

export default TextField;
