import React from 'react';
import { isMobile } from 'react-device-detect';
import { Coworking, CoworkingSvg } from 'shared/assets';
import { BipartiteViewport } from 'shared/ui';
import { Paragraph } from 'shared/theme';
import { FirstRow, SecondRow } from './styledComponents';

function HeadlineViewport() {
  return (
    <BipartiteViewport
      TitleComponent={
        <>
          <FirstRow>Your Partner,</FirstRow>
          <SecondRow>Not Just Your Software Agency</SecondRow>
        </>
      }
      SubtitleComponent={
        <Paragraph>
          Micralis is a privately-owned software development agency specialized
          in web and mobile development. Established in 2021, we’re a new player
          on the market backed by years of experience working with international
          clients.
        </Paragraph>
      }
      ImageComponent={isMobile ? <CoworkingSvg /> : <Coworking />}
    />
  );
}

export default HeadlineViewport;
