import styled from 'styled-components';
import { colors } from 'shared/theme';

const Row = styled.div`
  font-family: 'Bebas Neue', sans-serif;
  font-size: 4.5rem;
`;

export const FirstRow = styled(Row)`
  color: ${colors.black};
`;

export const SecondRow = styled(Row)`
  color: ${colors.darkTitle};
`;
