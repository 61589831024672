import { useLayoutEffect } from 'react';
import WebFont from 'webfontloader';

const useCustomFonts = () => {
  useLayoutEffect(() => {
    WebFont.load({
      google: {
        families: ['Bebas Neue', 'Montserrat'],
      },
    });
  }, []);
};

export default useCustomFonts;
