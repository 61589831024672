import React from 'react';
import PropTypes from 'prop-types';
import { StyledViewport } from './styledComponents';

function Viewport({ children, isOnlyViewport }) {
  return (
    <StyledViewport isOnlyViewport={isOnlyViewport}>{children}</StyledViewport>
  );
}

Viewport.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  isOnlyViewport: PropTypes.bool,
};

export default Viewport;
