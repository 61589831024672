import React from 'react';
import { PageContainer } from 'shared/ui';
import {
  HeadlineViewport,
  AboutUsViewport,
  HowWeWorkViewport,
  ContactUsViewport,
} from 'modules/home';

function HomePage() {
  return (
    <PageContainer>
      <HeadlineViewport />
      <AboutUsViewport />
      <HowWeWorkViewport />
      <ContactUsViewport />
    </PageContainer>
  );
}

export default HomePage;
