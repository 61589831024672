import { useLayoutEffect, useState } from 'react';

const useIsPageScrollable = () => {
  const [isPageScrollable, setIsPageScrollable] = useState(false);

  useLayoutEffect(() => {
    if (window.innerHeight < document.body.scrollHeight) {
      setIsPageScrollable(true);
    }
  }, []);

  return isPageScrollable;
};

export default useIsPageScrollable;
