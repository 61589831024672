import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'shared/ui';
import { routes, hrefs } from 'modules/main';
import { Container } from './styledComponents';

function NavBarMenu() {
  const history = useHistory();

  return (
    <Container>
      <a href={hrefs.aboutUs}>
        <Button title="About us" />
      </a>
      <a href={hrefs.howWeWork}>
        <Button title="How we work" />
      </a>
      <Button title="Careers" onClick={() => history.push(routes.careers)} />
    </Container>
  );
}

export default NavBarMenu;
