import styled from 'styled-components';
import { BreakpointUtils } from '../../utils';

export const Container = styled.div`
  display: flex;
  align-items: center;

  > button,
  a {
    margin-left: 20px;
    margin-right: 20px;
  }

  > button:first-child {
    margin-left: 0;
  }
  > button:last-child {
    margin-right: 0;
  }

  @media ${BreakpointUtils.deviceWidths.tablet} {
    display: none;
  }
`;
