import { ValidationUtils } from 'shared/utils';

export const validateContactUsForm = (values) => {
  const errors = {};

  if (ValidationUtils.isEmpty(values.name)) {
    errors.name = 'Name is required';
  }

  if (!ValidationUtils.isEmail(values.email)) {
    errors.email = 'Not a valid email format';
  }
  if (ValidationUtils.isEmpty(values.email)) {
    errors.email = 'Email is required';
  }
  if (ValidationUtils.isEmpty(values.phone)) {
    errors.phone = 'Phone is required';
  }
  if (!values.about) {
    errors.about = 'About is required';
  }
  return errors;
};
