import React, { useEffect } from 'react';
import { PageContainer } from 'shared/ui';
import { CareersViewport } from '../../components';

function CareersPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // dummy comment to force deploy
  return (
    <PageContainer>
      <CareersViewport />
    </PageContainer>
  );
}

export default CareersPage;
