import styled from 'styled-components';
import { colors } from 'shared/theme';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Row = styled.div`
  font-family: 'Bebas Neue', sans-serif;
  font-size: 4.5rem;
`;

export const FirstRow = styled(Row)`
  color: ${colors.black};
`;

export const SecondRow = styled(Row)`
  color: ${colors.darkTitle};
`;

export const Info = styled.div`
  margin-top: 2rem;
  margin-right: 2rem;
`;

export const ImageContainer = styled.div`
  > svg {
    width: 50vw;
    height: 100%;
  }
`;
