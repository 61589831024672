import React from 'react';
import { isMobile } from 'react-device-detect';
import { PageContainer } from 'shared/ui';
import { NotFound, NotFoundSvg } from 'shared/assets';
import { Container } from './styledComponents';

function NotFoundPage() {
  return (
    <PageContainer>
      <Container>{isMobile ? <NotFoundSvg /> : <NotFound />}</Container>
    </PageContainer>
  );
}

export default NotFoundPage;
