import Headroom from 'react-headroom';
import HamburgerMenu from 'react-hamburger-menu';
import styled from 'styled-components';
import { BreakpointUtils } from 'shared/utils';
import { colors } from 'shared/theme';

export const StyledHeader = styled(Headroom)`
  .headroom {
    background-color: ${colors.appBackground};
    height: 9vh;
    @media ${BreakpointUtils.deviceWidths.desktop} {
      padding-left: ${BreakpointUtils.deviceHorizontalPaddings.desktop};
      padding-right: ${BreakpointUtils.deviceHorizontalPaddings.desktop};
    }

    @media ${BreakpointUtils.deviceWidths.tablet} {
      padding-left: ${BreakpointUtils.deviceHorizontalPaddings.tablet};
      padding-right: ${BreakpointUtils.deviceHorizontalPaddings.tablet};
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: inherit;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${BreakpointUtils.deviceWidths.tablet} {
    display: none;
  }
`;

export const StyledHamburgerMenu = styled(HamburgerMenu)`
  align-self: center;
  cursor: pointer;
  @media ${BreakpointUtils.deviceWidths.desktop} {
    display: none;
  }
`;

export const DrawerButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16vw;
`;
