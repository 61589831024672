import { useCallback, useState } from 'react';

const useBoolean = (initialValue) => {
  const [value, setValue] = useState(initialValue || false);

  const toggleValue = useCallback(() => {
    setValue((currentValue) => !currentValue);
  }, []);

  return [value, toggleValue, setValue];
};

export default useBoolean;
