import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  > div:first-child {
    margin-top: 2vh;
  }
  > div:last-child {
    margin-bottom: 0;
  }

  > button {
    margin-top: 1vh;
  }
`;
