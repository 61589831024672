import React from 'react';
import { isMobile } from 'react-device-detect';
import { BipartiteViewport } from 'shared/ui';
import { Paragraph } from 'shared/theme';
import { Dashboard, DashboardSvg } from 'shared/assets';
import { anchors } from 'modules/main';
import { FirstRow, SecondRow } from './styledComponents';

function HowWeWorkViewport() {
  return (
    <BipartiteViewport
      TitleComponent={
        <>
          <FirstRow>IT powers everything.</FirstRow>
          <SecondRow>We power IT.</SecondRow>
        </>
      }
      SubtitleComponent={
        <Paragraph>
          There’s no silver bullet that will solve each and every challenge you
          might face. Business needs are different. People you work with are
          different. Over the years, we’ve shaped a methodology that helps us
          understand every client, and tailor our approach to meet every product
          vision. This is why we excel.
        </Paragraph>
      }
      ImageComponent={isMobile ? <DashboardSvg /> : <Dashboard />}
      idAnchorTag={anchors.howWeWork}
    />
  );
}

export default HowWeWorkViewport;
