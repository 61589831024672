import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BipartiteViewport, Snackbar } from 'shared/ui';
import { Paragraph } from 'shared/theme';
import { MessageSent, MessageSentSvg } from 'shared/assets';
import { anchors } from 'modules/main';
import { ContactUsForm } from '../ContactUsForm';

import { Row } from './styledComponents';

const SNACKBAR_SEVERITY = {
  SUCCESS: 'success',
  ERROR: 'error',
};

function ContactUsViewport() {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState(
    SNACKBAR_SEVERITY.SUCCESS,
  );

  function showSuccessSnackbar() {
    setSnackbarSeverity(SNACKBAR_SEVERITY.SUCCESS);
    setIsSnackbarOpen(true);
  }

  function showErrorSnackbar() {
    setSnackbarSeverity(SNACKBAR_SEVERITY.ERROR);
    setIsSnackbarOpen(true);
  }

  function closeSnackbar() {
    setIsSnackbarOpen(false);
  }

  return (
    <>
      <BipartiteViewport
        TitleComponent={<Row>Get in touch with us</Row>}
        SubtitleComponent={
          <>
            <Paragraph>
              You think we're a good fit for you and your idea? Well, we're
              happy to hear that! Please answer a couple of questions and we'll
              get back to you. Or if you prefer, message us directly at{' '}
              {process.env.REACT_APP_EMAIL_ADDRESS_CONTACT}
            </Paragraph>
            <ContactUsForm
              showSuccessSnackbar={showSuccessSnackbar}
              showErrorSnackbar={showErrorSnackbar}
            />
          </>
        }
        ImageComponent={isMobile ? <MessageSentSvg /> : <MessageSent />}
        isInverted
        idAnchorTag={anchors.contactUs}
      />
      <Snackbar
        handleClose={closeSnackbar}
        isOpen={isSnackbarOpen}
        severity={snackbarSeverity}
        text={
          snackbarSeverity === SNACKBAR_SEVERITY.SUCCESS
            ? 'Email successfully sent!'
            : `An error occurred while sending the email. Please contact the agency directly at ${process.env.REACT_APP_EMAIL_ADDRESS_CONTACT}`
        }
      />
    </>
  );
}

export default ContactUsViewport;
