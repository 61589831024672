import React from 'react';
import { isMobile } from 'react-device-detect';
import { BipartiteViewport } from 'shared/ui';
import { Paragraph } from 'shared/theme';
import { Celebration, CelebrationSvg } from 'shared/assets';
import { anchors } from 'modules/main';
import { Row } from './styledComponents';

function AboutUsViewport() {
  return (
    <BipartiteViewport
      TitleComponent={<Row>We deliver on our promises</Row>}
      SubtitleComponent={
        <Paragraph>
          We are a small team of dedicated, humble and reliable people that gets
          things done and enjoys the process. Having been in the software
          industry for some time now, we decided to embark on another journey,
          create a great company of our own and see what the future has in
          store.
        </Paragraph>
      }
      ImageComponent={isMobile ? <CelebrationSvg /> : <Celebration />}
      isInverted
      idAnchorTag={anchors.aboutUs}
    />
  );
}

export default AboutUsViewport;
