import React from 'react';
import { isMobile } from 'react-device-detect';
import { BipartiteViewport } from 'shared/ui';
import { Careers, CareersSvg } from 'shared/assets';
import { Paragraph } from 'shared/theme';
import { Row } from './styledComponents';

function CareersViewport() {
  return (
    <BipartiteViewport
      TitleComponent={
        <>
          <Row>Want to join the team?</Row>
        </>
      }
      SubtitleComponent={
        <Paragraph>
          We're currently not recruiting. However, if you think there's
          something you can bring to the table be sure to ping us at{' '}
          {process.env.REACT_APP_EMAIL_ADDRESS_CONTACT}
        </Paragraph>
      }
      ImageComponent={isMobile ? <CareersSvg /> : <Careers />}
      isOnlyViewport
    />
  );
}

export default CareersViewport;
