import styled from 'styled-components';
import { colors } from 'shared/theme';

export const StyledButton = styled.button`
  all: unset;
  font-family: 'Bebas Neue', sans-serif;
  text-transform: none;
  font-size: 1.5rem;
  color: ${(props) => (!props.inverted ? colors.black : colors.primary)};
  cursor: pointer;
  position: relative;
  display: inline-block;
  overflow: hidden;
  background: linear-gradient(
    to right,
    ${(props) => (!props.inverted ? colors.primary : colors.black)},
    ${(props) => (!props.inverted ? colors.primary : colors.black)} 50%,
    ${(props) => (!props.inverted ? colors.black : colors.primary)} 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${(props) =>
    !props.isMobile ? 'transparent' : 'unset'};
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 250ms ease;

  &:hover {
    background-position: 0 100%;
  }
`;
