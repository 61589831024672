import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from '../NavBar';
import { Container } from './styledComponents';

function RouteContainer({ children }) {
  return (
    <Container>
      <NavBar />
      {children}
    </Container>
  );
}

RouteContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default RouteContainer;
