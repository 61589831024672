import React from 'react';
import PropTypes from 'prop-types';
import { isMobile, isSafari } from 'react-device-detect';
import ScrollAnimation from 'react-animate-on-scroll';
import { Viewport } from '../Viewport';
import {
  TextContainer,
  ImageContainer,
  SubtitleContainer,
} from './styledComponents';

function BipartiteViewport({
  TitleComponent,
  SubtitleComponent,
  ImageComponent,
  isInverted,
  isOnlyViewport,
  idAnchorTag,
}) {
  const ViewportContent = (
    <div id={idAnchorTag}>
      <Viewport isOnlyViewport={isOnlyViewport}>
        {isInverted && (
          <ImageContainer>
            <img src={ImageComponent.type} alt="fef" />
          </ImageContainer>
        )}
        <TextContainer isInverted={isInverted}>
          {TitleComponent}
          <SubtitleContainer>{SubtitleComponent}</SubtitleContainer>
        </TextContainer>
        {!isInverted && (
          <ImageContainer>
            <img src={ImageComponent.type} alt="fef" />
          </ImageContainer>
        )}
      </Viewport>
    </div>
  );

  if (!isMobile || isSafari) {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOnce>
        {ViewportContent}
      </ScrollAnimation>
    );
  }

  return ViewportContent;
}

BipartiteViewport.propTypes = {
  TitleComponent: PropTypes.node.isRequired,
  SubtitleComponent: PropTypes.node.isRequired,
  ImageComponent: PropTypes.elementType.isRequired,
  isInverted: PropTypes.bool,
  isOnlyViewport: PropTypes.bool,
  idAnchorTag: PropTypes.string,
};

export default BipartiteViewport;
