import styled from 'styled-components';

export const Container = styled.div`
  height: calc(91vh - 15vh);
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 50vw;
    height: 100%;
  }
`;
