import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from 'shared/theme';

export const TitleContainer = styled.span`
  margin-right: 0.5vw;
  font-family: 'Bebas Neue', sans-serif;
`;

export const StyledButton = styled(Button)`
  height: 56px;

  .MuiButton-label {
    font-size: 1.4rem;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-root {
    color: ${colors.white};
  }
`;
