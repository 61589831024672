export const anchors = {
  aboutUs: 'about-us',
  howWeWork: 'how-we-work',
  contactUs: 'get-in-touch',
};

export const hrefs = {
  aboutUs: `/#${anchors.aboutUs}`,
  howWeWork: `/#${anchors.howWeWork}`,
  contactUs: `/#${anchors.contactUs}`,
};
