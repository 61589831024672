import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { StyledButton } from './styledComponents';

function Button({ title, onClick, inverted }) {
  return (
    <StyledButton
      type="button"
      onClick={onClick}
      inverted={inverted}
      isMobile={isMobile}
    >
      {title}
    </StyledButton>
  );
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
};

export default Button;
