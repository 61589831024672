const sizes = {
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};

const deviceWidths = {
  tablet: `(max-width: ${sizes.md})`,
  desktop: `(min-width: ${sizes.md})`,
};

const deviceHorizontalPaddings = {
  tablet: '5vw',
  desktop: '5vw',
};

export default {
  deviceWidths,
  deviceHorizontalPaddings,
};
