import styled from 'styled-components';
import { BreakpointUtils } from 'shared/utils';

export const StyledViewport = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: ${(props) =>
    !props.isOnlyViewport ? '91vmin' : 'calc(91vh - 15vh)'};

  :first-child {
    flex: 1;
  }

  :last-child {
    flex: 1;
  }

  @media ${BreakpointUtils.deviceWidths.tablet} {
    flex-direction: column;
    justify-content: space-around;
    height: auto;
  }
`;
