import React from 'react';
import PropTypes from 'prop-types';
import { PageFooter } from '../PageFooter';
import { Container } from './styledComponents';

function PageContainer({ children }) {
  return (
    <Container>
      {children}
      <PageFooter />
    </Container>
  );
}

PageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default PageContainer;
