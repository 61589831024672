import React from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
import { StyledSnackbar } from './styledComponents';

function Snackbar({ isOpen, handleClose, severity, text }) {
  return (
    <StyledSnackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
      >
        {text}
      </MuiAlert>
    </StyledSnackbar>
  );
}

Snackbar.propTypes = {
  handleClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['success', 'error']).isRequired,
  isOpen: PropTypes.bool,
};
export default Snackbar;
