import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { TextField, IconButton } from 'shared/ui';
import { sendProjectMail } from 'shared/firebase';
import { validateContactUsForm } from '../../services/validators';
import { Container } from './styledComponents';

function ContactUsForm({ showSuccessSnackbar, showErrorSnackbar }) {
  async function handleSendEmail({ name, email, phone, about }) {
    try {
      await sendProjectMail({ name, email, phone, about });
      showSuccessSnackbar();
    } catch (e) {
      showErrorSnackbar();
    }
  }

  return (
    <Form
      onSubmit={handleSendEmail}
      validate={validateContactUsForm}
      render={({ handleSubmit, submitting, pristine, form }) => (
        <form
          onSubmit={async (event) => {
            await handleSubmit(event);
            form.restart();
          }}
          noValidate
        >
          <Container>
            <TextField label="Name" name="name" required />
            <TextField label="Email" name="email" required />
            <TextField label="Phone" name="phone" required />
            <TextField label="About" name="about" required multiline />
            <IconButton
              type="submit"
              title="Send us a message"
              disabled={submitting || pristine}
              loading={submitting}
            />
          </Container>
        </form>
      )}
    />
  );
}
ContactUsForm.propTypes = {
  showSuccessSnackbar: PropTypes.func.isRequired,
  showErrorSnackbar: PropTypes.func.isRequired,
};
export default ContactUsForm;
