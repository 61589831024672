import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { routes } from 'modules/main';
import { HomePage } from 'modules/home';
import { NotFoundPage } from 'modules/notFound';
import { RouteContainer } from 'shared/ui';
import { useCustomFonts } from 'shared/hooks';
import { CareersPage } from './modules/careers';

function App() {
  useCustomFonts();

  return (
    <Router>
      <RouteContainer>
        <Switch>
          <Route exact path={routes.home} component={HomePage} />
          <Route exact path={routes.careers} component={CareersPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </RouteContainer>
    </Router>
  );
}

export default App;
