import styled from 'styled-components';
import { BreakpointUtils } from 'shared/utils';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${(props) => (!props.isInverted ? '2vw' : '0')};
  margin-left: ${(props) => (props.isInverted ? '2vw' : '0')};
`;

export const SubtitleContainer = styled.div`
  margin-top: 2rem;
`;

export const ImageContainer = styled.div`
  @media ${BreakpointUtils.deviceWidths.tablet} {
    order: 1;
  }

  > img,
  svg {
    width: 50vw;
    height: 100%;

    @media ${BreakpointUtils.deviceWidths.tablet} {
      width: 90vw;
      margin-top: 10vw;
      margin-bottom: 10vw;
    }
  }
`;
