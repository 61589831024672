import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import {
  TitleContainer,
  StyledButton,
  StyledCircularProgress,
} from './styledComponents';

function IconButton({ title, disabled, loading }) {
  return (
    <StyledButton
      variant="contained"
      color="primary"
      endIcon={!loading ? <Icon>send</Icon> : null}
      type="submit"
      disabled={disabled}
    >
      {!loading && <TitleContainer>{title}</TitleContainer>}
      {loading && <StyledCircularProgress size={25} thickness={5} />}
    </StyledButton>
  );
}

IconButton.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default IconButton;
