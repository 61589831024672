import styled from 'styled-components';
import { LinkedIn, Instagram, MicralisLogo, Phone, Email } from 'shared/assets';
import { BreakpointUtils } from 'shared/utils';

export const Container = styled.div`
  height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${BreakpointUtils.deviceWidths.tablet} {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;
export const Item = styled.div``;
export const ItemLogo = styled.div`
  min-width: 142px;
`;

export const MottoContainer = styled.div`
  text-align: center;
  > span {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    margin-left: 10px;
    margin-right: 10px;
  }

  > button:first-child {
    margin-left: 0;
  }
  > button:last-child {
    margin-right: 0;
  }
`;

export const StyledLinkedinIcon = styled(LinkedIn)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.3;

  &:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1;
  }
`;

export const StyledInstagramIcon = styled(Instagram)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.3;

  &:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1;
  }
`;

export const StyledPhoneIcon = styled(Phone)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.3;

  &:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1;
  }
`;

export const StyledEmailIcon = styled(Email)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.3;

  &:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1;
  }
`;

export const SmallMicralisLogo = styled(MicralisLogo)`
  height: 1.4rem;
`;
